import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import FullWidthContainer from './containers/fullWidthContainer';
import Header from './header';
import MenuSidebar from './menu/menuSidebar';
import Footer from './footer';
import Announcement from './announcement';
import SEO from './seo';
import Banner from './banner';

const Layout = ({
  isCompactLayout,
  pageTitle,
  children,
  contentInsideBackground,
  backgroundImageFluidStack,
  backgroundStyle,
  heroSlantClassName,
  useTallBannerBackground,
  logoClassName,
  isBannerVisible,
}) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      navbarLogo: allPrismicCompanylogo(
        filter: { uid: { eq: "company-logo" } }
      ) {
        edges {
          node {
            uid
            data {
              logo {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
      sidebarLogo: allPrismicCompanylogo(
        filter: { uid: { eq: "company-logo-color" } }
      ) {
        edges {
          node {
            uid
            data {
              logo {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      prismicBannerAnnouncement {
        data {
          announcement_text
        }
      }
      prismicFooter {
        data {
          address_one {
            richText
          }
          contact_us_email {
            richText
          }
          contact_us_label {
            richText
          }
          contact_us_phone {
            richText
          }
          copyright_text {
            richText
          }
        }
      }
      allPrismicSocialMedia {
        edges {
          node {
            data {
              social_media_type
              social_media_account_link {
                url
                target
              }
            }
          }
        }
      }
      prismicFooterNavItems {
        data {
          nav_items {
            link_text {
              richText
            }
            nav_link {
              url
              link_type
            }
          }
        }
      }
      prismicHeader {
        data {
          body {
            ... on PrismicHeaderDataBodyNavMenu {
              items {
                child_link_label {
                  richText
                }
                child_link_url {
                  url
                  link_type
                }
              }
              primary {
                label {
                  richText
                }
                link {
                  link_type
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const { site } = data;
  // no need for the root nav_items anymore. Can just use the 'body' and primary/items to
  // get the parent child relationship
  const menuItems = data.prismicHeader.data.body;
  const navbarLogoData = data.navbarLogo.edges[0].node.data;
  const navbarCompanyLogoFluid = navbarLogoData.logo.gatsbyImageData;
  const navbarCompanyLogoAlt = navbarLogoData.logo.alt;

  const sidebarLogoData = data.sidebarLogo.edges[0].node.data;
  const sidebarLogoFluid = sidebarLogoData.logo.gatsbyImageData;
  const sidebarLogoAlt = sidebarLogoData.logo.alt;

  const socialMedias = data.allPrismicSocialMedia.edges;

  const footerData = {
    contactInfo: data.prismicFooter.data,
    copyright: data.prismicFooter.data.copyright_text,
    navItems: data.prismicFooterNavItems.data.nav_items,
  };

  const bannerAnnouncement =
    data.prismicBannerAnnouncement.data.announcement_text;

  const [isMenuOpen, setIsMenuOpen] = useState(0);

  const closeMenu = () => (isMenuOpen ? setIsMenuOpen(0) : {});

  let content = (
    <div
      className='mx-auto flex h-full flex-col'
      onClick={() => closeMenu()}
      role='document'
    >
      <FullWidthContainer role='article'>
        <Header
          isCompactLayout={isCompactLayout}
          menuItems={menuItems}
          logoFluid={navbarCompanyLogoFluid}
          logoAlt={navbarCompanyLogoAlt}
          logoClassName={logoClassName}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          socialMedias={socialMedias}
        />
      </FullWidthContainer>
      {contentInsideBackground ? contentInsideBackground() : null}
    </div>
  );

  const backgroundComponentLargeBackgroundImageFluidStack =
    backgroundImageFluidStack && backgroundImageFluidStack.length > 0
      ? `url(${backgroundImageFluidStack[0]})`
      : '';
  const backgroundComponentLarge = (
    <div
      style={
        backgroundStyle
          ? backgroundStyle
          : {
              height: `${useTallBannerBackground ? '150%' : '100%'}`,
              minHeight: `768px`,
              background: backgroundComponentLargeBackgroundImageFluidStack,
              backgroundPosition: `center`,
              backgroundRepeat: `no-repeat`,
              backgroundSize: `cover`,
            }
      }
    >
      {content}
    </div>
  );

  const backgroundComponentSmallBackgroundImageFluidStack =
    backgroundImageFluidStack && backgroundImageFluidStack.length > 0
      ? backgroundImageFluidStack[1]
      : '';
  const backgroundComponentSmall = (
    <BackgroundImage
      Tag='div'
      fadeIn='critical'
      fluid={[
        `linear-gradient(rgba(60,98,115,0.8), rgba(60,98,115,0.8))`,
        backgroundComponentSmallBackgroundImageFluidStack.fluid,
      ]}
      style={backgroundStyle ? backgroundStyle : { height: `100%` }}
    >
      {content}
    </BackgroundImage>
  );

  let mainClassName = null;

  if (isCompactLayout) {
    mainClassName = `bg-denimblue`;
  } else {
    mainClassName = `${heroSlantClassName} sm:height-hero-sm md:height-hero-full flex flex-col`;
  }

  return (
    <div className='md:h-full'>
      <SEO site={site} title={pageTitle} />
      <Banner
        isVisible={isBannerVisible}
        announcementText={bannerAnnouncement}
      />
      <div className='ie11 bg-denimblue px-4 py-3 text-center text-white'>
        <p>
          Internet Explorer is no longer supported by Microsoft. Our website is
          best viewed in a modern browser such as Google Chrome or Microsoft
          Edge. Thank you!
        </p>
      </div>
      {isMenuOpen ? (
        <div className='fixed z-10 h-full w-full' onClick={() => closeMenu()} />
      ) : null}
      <main className={mainClassName}>
        {backgroundImageFluidStack ? (
          <>
            <div className='block h-full lg:hidden'>
              {backgroundComponentSmall}
            </div>
            <div className='hidden h-full lg:block'>
              {backgroundComponentLarge}
            </div>
          </>
        ) : (
          <>{content}</>
        )}
      </main>
      <MenuSidebar
        onMenuClick={closeMenu}
        logoFluid={sidebarLogoFluid}
        logoAlt={sidebarLogoAlt}
        menuItems={menuItems}
        className={`${
          isMenuOpen ? 'transition-onscreen-from-right' : 'offscreen-right'
        }`}
      />

      {children}
      <Announcement />
      <Footer
        menuItems={footerData.navItems}
        companyLogoFluid={navbarCompanyLogoFluid}
        companyLogoAlt={navbarCompanyLogoAlt}
        contactInfo={footerData.contactInfo}
        copyright={footerData.copyright.richText}
        socialMedias={socialMedias}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
