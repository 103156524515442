import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useStaticQuery, graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { linkResolver } from '../utils/linkResolver';
import { phoneNumberHtmlSerializer } from '../utils/phoneNumberHtmlSerializer';

const Announcement = () => {
  const data = useStaticQuery(graphql`
    query AnnouncementQuery {
      prismicAnnouncement {
        data {
          content {
            richText
          }
          show_announcement
        }
      }
    }
  `);

  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const hasAcknowledgedAnnouncement = 'hasAcknowledgedAnnouncement';
  useEffect(() => {
    setTimeout(() => {
      if (
        !cookies[hasAcknowledgedAnnouncement] &&
        data.prismicAnnouncement.data.show_announcement
      ) {
        setShowAnnouncement(true);
      }
      if (
        cookies[hasAcknowledgedAnnouncement] ||
        !data.prismicAnnouncement.data.show_announcement
      ) {
        setShowAnnouncement(false);
      }
    }, 500);
  });

  return (
    <div
      className='announcement-container'
      style={{
        opacity: `${showAnnouncement ? 1 : 0}`,
        display: `${showAnnouncement ? 'flex' : 'none'}`,
        transition: `all 100ms linear 0s`,
      }}
    >
      <div
        className='announcement-content'
        onClick={() => {
          setCookie(hasAcknowledgedAnnouncement, true);
          setShowAnnouncement(false);
        }}
      >
        <div className='text-sm sm:text-base' style={{ textAlign: `center` }}>
          <PrismicRichText
            field={data.prismicAnnouncement.data.content.richText}
            linkResolver={linkResolver}
            components={{
              hyperlink: phoneNumberHtmlSerializer,
            }}
          />
        </div>
        <div className='announcement-content-close'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='15'
            height='15'
            viewBox='0 0 24 24'
            fill='none'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <line x1='18' y1='6' x2='6' y2='18'></line>
            <line x1='6' y1='6' x2='18' y2='18'></line>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
