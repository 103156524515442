import React from 'react';
import { Link } from 'gatsby';
import {
  childLinkResolver,
  newLinkResolver,
} from '../../utils/newLinkResolver';
import MobileExpandableMenuItem from './mobileExpandableMenuItem';
import { RichText } from 'prismic-reactjs';

const MenuItemSidebar = ({ menuItems, className }) => {
  return (
    <div className='flex flex-col'>
      {menuItems
        .filter(
          (mi) =>
            !mi.primary.label.richText[0].text.toLowerCase().includes('home')
        )
        .map((menuItem, index) => {
          const resolvedLink = newLinkResolver(menuItem);

          const resolvedChildLinks = menuItem.items
            .filter((f) => f.child_link_url.url)
            .map((field) => {
              return childLinkResolver(field);
            });

          let content = null;

          if (resolvedChildLinks.length === 0) {
            content = resolvedLink.isExternal ? (
              <a
                href={resolvedLink.url}
                target='_blank'
                rel='noopener noreferrer'
                className='hover:text-primary hover:font-medium hover:underline'
              >
                <div className='px-1' style={{ padding: `0.65rem 0` }}>
                  <span>
                    {RichText.asText(menuItem.primary.label.richText)}
                  </span>
                </div>
              </a>
            ) : (
              <Link
                className='hover:text-primary hover:font-medium hover:underline'
                to={resolvedLink.url}
              >
                <div className='px-1' style={{ padding: `0.65rem 0` }}>
                  <span>
                    {RichText.asText(menuItem.primary.label.richText)}
                  </span>
                </div>
              </Link>
            );
          }
          if (resolvedChildLinks.length > 0 || resolvedLink.url.includes('calendar')) {
            content = (
              <MobileExpandableMenuItem
                url={resolvedLink.url}
                label={RichText.asText(menuItem.primary.label.richText)}
                isExternal={resolvedLink.isExternal}
                childLinks={resolvedChildLinks}
              />
            );
          }

          return (
            <div
              key={index}
              className={`font-medium uppercase text-gray-700 ${className}`}
            >
              {index > 0 ? <div className='h-px bg-gray-500'></div> : null}
              {content}
            </div>
          );
        })}
    </div>
  );
};

export default MenuItemSidebar;
