import React from 'react';
import { getSocialMediaIconFromType } from '../utils/getSocialMediaIconFromType';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { footerLinkResolver } from '../utils/footerLinkResolver';
import { RichText } from 'prismic-reactjs';

const Footer = ({ menuItems, contactInfo, copyright, socialMedias }) => {
  const { contact_us_label, address_one, contact_us_email, contact_us_phone } =
    contactInfo;

  const copyrightText = RichText.asText(copyright);

  const contactUsLabelText = RichText.asText(contact_us_label.richText);
  const addressOneText = RichText.asText(address_one.richText);
  const contactUsEmailText = RichText.asText(contact_us_email.richText);
  const contactUsPhoneText = RichText.asText(contact_us_phone.richText);

  return (
    <footer className='flex items-center bg-darkslategray pt-3 text-white md:pt-6'>
      <div className='w-full px-3 md:px-12 lg:px-48 mega:px-56'>
        <div className='flex flex-row items-center justify-between uppercase md:justify-around'>
          <div className='mr-1 flex w-9/12 flex-col lg:w-full'>
            <div className='mt-4 flex flex-col text-left text-xs'>
              <span>{contactUsLabelText}</span>
              <span>{addressOneText}</span>
              <span className='break-all underline'>
                <a
                  href={`mailto:${contactUsEmailText}`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {contactUsEmailText}
                </a>
              </span>
              <a
                className='underline'
                href={`tel:${contactUsPhoneText.replace('.', '-')}`}
              >
                {contactUsPhoneText}
              </a>
            </div>
            <div className='flex items-center'>
              {socialMedias.map((sm, index) => {
                return (
                  <div key={index} className='mr-2 mt-2'>
                    <a
                      href={sm.node.data.social_media_account_link.url}
                      target={
                        sm.node.data.social_media_account_link.target || ''
                      }
                    >
                      <FontAwesomeIcon
                        icon={getSocialMediaIconFromType(
                          sm.node.data.social_media_type
                        )}
                        size='2x'
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='lg:site-info-v-splitter bg-white' />
          <div className='text-primary my-3 flex flex-col justify-between text-sm font-medium'>
            <div className='flex flex-col flex-wrap justify-center pl-2 md:flex-row'>
              {menuItems.map((menuItem, index) => {
                const menuItemText = RichText.asText(
                  menuItem.link_text.richText
                );
                const resolvedLink = footerLinkResolver(menuItem);
                return resolvedLink.isExternal ? (
                  <a
                    key={index}
                    href={resolvedLink.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-xxs hover:text-primary mx-2 hover:font-semibold md:text-xs'
                  >
                    <span>{menuItemText}</span>
                  </a>
                ) : (
                  <Link
                    key={index}
                    className='text-xxs hover:text-primary mx-2 hover:font-semibold md:text-xs'
                    to={resolvedLink.url}
                  >
                    <span>{menuItemText}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className='text-center text-xs'>
          <p>{copyrightText}</p>
        </div>
        <div className='text-center text-xs'>
          <p>
            Website created by{' '}
            <a
              href='https://redbrickroadstudio.com'
              rel='noopener noreferrer'
              target='_blank'
            >
              Red Brick Road Studio
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
