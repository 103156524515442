import React from 'react';

const FullWidthContainer = ({ className, children }) => {
  return (
    <div className={`w-full px-8 md:px-12 lg:px-20 ${className}`}>
      {children}
    </div>
  );
};

export default FullWidthContainer;
