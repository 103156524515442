/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react';
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';

const Banner = ({ announcementText, cta_link, cta_label, isVisible }) => {
  const [isOpen, setIsOpen] = useState(true);

  let content = null;

  if (isOpen && isVisible) {
    content = (
      <div className='bg-denimblue'>
        <div className='mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8'>
          <div className='flex flex-wrap items-center justify-between'>
            <div className='flex w-0 flex-1 items-center'>
              <span className='flex rounded-lg bg-denimblue p-2'>
                <SpeakerphoneIcon
                  className='h-6 w-6 text-white'
                  aria-hidden='true'
                />
              </span>
              <p className='ml-3 font-medium text-white'>
                <span className='md:hidden'>{announcementText}</span>
                <span className='hidden md:inline'>{announcementText}</span>
              </p>
            </div>
            {cta_link ? (
              <div className='order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto'>
                <Link
                  to={cta_link}
                  className='flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-opaqueblue shadow-sm hover:bg-blue-50'
                >
                  {cta_label}
                </Link>
              </div>
            ) : null}
            <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-3'>
              <button
                type='button'
                className='-mr-1 flex rounded-md p-2 hover:bg-darkslategray focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2'
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className='sr-only'>Dismiss</span>
                <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return content;
};

export default Banner;
