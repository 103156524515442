export const linkResolver = (doc) => {
  if (doc.uid === 'home') {
    return { isExternal: false, url: '/' };
  }
  if (doc.uid === 'about-us') {
    return { isExternal: false, url: '/about-us' };
  }
  if (doc.uid === 'programs') {
    return { isExternal: false, url: '/programs' };
  }
  if (doc.uid === 'playscapes') {
    return { isExternal: false, url: '/playscapes' };
  }

  if (doc.uid === 'admissions') {
    return { isExternal: false, url: '/admissions' };
  }

  if (doc.uid === 'photos') {
    return { isExternal: false, url: '/photos' };
  }

  if (doc.uid === 'schedule-your-visit') {
    return { isExternal: false, url: '/schedule-your-visit' };
  }

  if (doc.uid === 'news') {
    return { isExternal: false, url: '/news' };
  }

  if (doc._linkType === 'Link.document' && doc._meta.uid === 'about-us') {
    return { isExternal: false, url: '/about-us' };
  }

  if (doc._linkType === 'Link.document' && doc._meta.uid === 'programs') {
    return { isExternal: false, url: '/programs' };
  }

  if (doc._linkType === 'Link.document' && doc._meta.uid === 'playscapes') {
    return { isExternal: false, url: '/playscapes' };
  }

  if (doc._linkType === 'Link.document' && doc._meta.uid === 'photos') {
    return { isExternal: false, url: '/photos' };
  }

  if (doc._linkType === 'Link.document' && doc._meta.uid === 'admissions') {
    return { isExternal: false, url: '/admissions' };
  }

  if (
    doc._linkType === 'Link.document' &&
    doc._meta.uid === 'schedule-your-visit'
  ) {
    return { isExternal: false, url: '/schedule-your-visit' };
  }

  if (doc._linkType === 'Link.document' && doc._meta.uid === 'news') {
    return { isExternal: false, url: '/news' };
  }

  if (doc._linkType === 'Link.file' && doc.uid === 'calendar') {
    return { isExternal: true, url: doc.link_url.url };
  }

  if (doc._linkType === 'Link.file' && doc.uid === 'covid-policy') {
    return { isExternal: true, url: doc.link_url.url };
  }

  if (
    doc.link_url &&
    doc.link_url._linkType === 'Link.file' &&
    doc.uid === 'calendar'
  ) {
    return { isExternal: true, url: doc.link_url.url };
  }

  if (
    doc.link_url &&
    doc.link_url._linkType === 'Link.file' &&
    doc.uid === 'covid-policy'
  ) {
    return { isExternal: true, url: doc.link_url.url };
  }

  // Backup for all other types
  return { isExternal: false, url: '/' };
};
