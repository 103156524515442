import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { phoneNumberUrlParser } from '../../utils/phoneNumberHtmlSerializer';
import Logo from '../logo';
import MenuItemSidebar from './menuItemSidebar';
import MobileExpandablePayInvoiceMenuItem from './mobileExpandablePayInvoiceMenuItem';

const MenuSidebar = ({
  onMenuClick,
  logoFluid,
  menuItems,
  logoAlt,
  className,
}) => {
  const data = useStaticQuery(graphql`
    query MenuSidebarQuery {
      prismicHeader {
        data {
          contact_phone_number {
            url
          }
        }
      }
    }
  `);

  const phoneNumberUrl = data.prismicHeader.data.contact_phone_number.url;

  return (
    <div
      className={`fixed inset-y-0 z-50 h-full w-2/3 bg-white shadow-xl md:w-1/3 ${className}`}
    >
      <div className='menu-scroll flex h-full flex-col justify-between'>
        <div className='flex flex-col'>
          <div
            className='mt-4 mr-4 h-6 cursor-pointer self-end'
            onClick={onMenuClick}
            role='menu'
            tabIndex={0}
          >
            <div
              className='mt-2 w-6 bg-gray-600'
              style={{
                height: `0.1rem`,
                transform: `translateY(0.37rem) rotate(45deg)`,
              }}
            />
            <div
              className='w-6 bg-gray-600'
              style={{
                height: `0.1rem`,
                transform: `translateY(0.3rem) rotate(-45deg)`,
              }}
            />
          </div>
          <div className='mt-2 flex w-full flex-col items-center justify-center tiny:mb-4 mobile:mb-6 sm:mb-8 md:mt-4'>
            <Logo
              logoFluid={logoFluid}
              className='max-w-xxxxs sm:max-w-xxxs mx-auto'
              alt={logoAlt}
            />
            <div className='mx-1 mt-4 flex items-center rounded bg-white px-3 py-2 font-semibold text-denimblue'>
              <p>Call us - </p>
              <a
                className='ml-1 hover:underline'
                href={phoneNumberUrl}
                rel='noopener noreferrer'
              >
                {phoneNumberUrlParser(phoneNumberUrl)}
              </a>
            </div>
          </div>
          <MenuItemSidebar menuItems={menuItems} className='mx-4' />
        </div>
      </div>
    </div>
  );
};

export default MenuSidebar;
