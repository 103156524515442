export const newLinkResolver = (doc) => {
  const primaryLinkType = doc.primary.link.link_type;
  if (primaryLinkType === 'Document') {
    return {
      isExternal: false,
      url: `/${doc.primary.label.richText[0].text
        .replace(/\s/g, '-')
        .toLowerCase()}`,
    };
  }
  if (primaryLinkType === 'Media' || primaryLinkType === 'Web') {
    return { isExternal: true, url: doc.primary.link.url };
  }
};

export const childLinkResolver = (field) => {
  const childLinkType = field.child_link_url.link_type;
  if (childLinkType === 'Document') {
    return {
      isExternal: false,
      url: `/${field.child_link_label.richText[0].text
        .replace(/\s/g, '-')
        .toLowerCase()}`,
      label: field.child_link_label.richText[0].text,
    };
  }
  if (childLinkType === 'Media') {
    return {
      isExternal: true,
      url: field.child_link_url.url,
      label: field.child_link_label.richText[0].text,
    };
  }
  if(childLinkType === 'Web'){
    return {
      isExternal: true,
      url: field.child_link_url.url,
      label: field.child_link_label.richText[0].text
    };
  }
  if (childLinkType === 'Any') {
    return { isExternal: false, url: '', label: '' };
  }
};
