import React from 'react';
import Logo from '../logo';

const NavbarSmall = ({
  isMenuOpen,
  setIsMenuOpen,
  isCompactLayout,
  logoFluid,
  logoAlt,
}) => {
  return (
    <nav
      className={`flex items-center xl:hidden ${
        isCompactLayout ? 'justify-between' : 'justify-end'
      }`}
    >
      {isCompactLayout ? (
        <div className='w-1/4'>
          <Logo
            logoFluid={logoFluid}
            className={`mx-auto md:mx-0`}
            alt={logoAlt}
          />
        </div>
      ) : null}
      <button
        className='border-primary flex items-center px-3 py-2 text-gray-100'
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <svg
          className='h-5 w-5 fill-current'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <title>Menu</title>
          <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
        </svg>
      </button>
    </nav>
  );
};

export default NavbarSmall;
