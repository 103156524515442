import { RichText } from 'prismic-reactjs';

export const footerLinkResolver = (doc) => {
  if (doc.nav_link.link_type === 'Document') {
    if (RichText.asText(doc.link_text.richText).toLowerCase() === 'home') {
      return { isExternal: false, url: '/' };
    }
    return {
      isExternal: false,
      url: `/${RichText.asText(doc.link_text.richText)
        .replace(/\s/g, '-')
        .toLowerCase()}`,
    };
  }
  if (doc.nav_link.link_type === 'Media' || doc.nav_link.link_type === 'Web') {
    return { isExternal: true, url: doc.nav_link.url };
  }
};
