import React from 'react';

export const phoneNumberHtmlSerializer = ({ children, node }) => {
  return (
    <a className='text-blue-500' href={node.data.url}>
      {children}
    </a>
  );
};

export const phoneNumberUrlParser = (phoneNumberUrl) => {
  return `${phoneNumberUrl.split(':')[1]}`;
};
