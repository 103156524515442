import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NavbarLarge from './navbar/navbarLarge';
import NavbarSmall from './navbar/navbarSmall';
import Logo from './logo';
import { PrismicRichText } from '@prismicio/react';
import { blueHyperlinkHtmlSerializer } from '../utils/blueHyperlinkHtmlSerializer';

const Header = ({
  logoFluid,
  logoAlt,
  menuItems,
  logoClassName,
  isMenuOpen,
  setIsMenuOpen,
  isCompactLayout,
  socialMedias,
}) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      prismicCompanylogo {
        data {
          logo {
            alt
            gatsbyImageData(width: 500)
          }
        }
      }
      prismicHeader {
        data {
          contact_phone_number {
            url
          }
          banner {
            richText
          }
        }
      }
    }
  `);

  const navbarLogoData = data.prismicCompanylogo.data.logo;
  const navbarCompanyLogoFluid = navbarLogoData.gatsbyImageData;
  const navbarCompanyLogoAlt = navbarLogoData.alt;
  const phoneNumberUrl = data.prismicHeader.data.contact_phone_number.url;
  const banner = data.prismicHeader.data.banner;

  return (
    <header>
      <div className='mx-auto flex flex-col justify-center pt-6 pb-4 md:pt-10'>
        <NavbarLarge
          menuItems={menuItems}
          phoneNumberUrl={phoneNumberUrl}
          isCompactLayout={isCompactLayout}
          logoFluid={navbarCompanyLogoFluid}
          logoAlt={navbarCompanyLogoAlt}
          socialMedias={socialMedias}
        />
        <NavbarSmall
          menuItems={menuItems}
          isCompactLayout={isCompactLayout}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          logoFluid={navbarCompanyLogoFluid}
          logoAlt={navbarCompanyLogoAlt}
        />
        {isCompactLayout ? null : (
          <div className='flex flex-col md:flex-row items-center justify-center md:justify-start'>
            <Logo
              logoFluid={logoFluid}
              className={`mx-auto md:mx-0 ${logoClassName}`}
              alt={logoAlt}
            />
            {/* <div className="inline-block grow px-3 py-2 my-8 md:my-0 md:ml-28 bg-white text-darkslategray md:text-xl lg:text-2xl text-center rounded shadow-md">
              <PrismicRichText
                field={banner.richText}
                components={{
                  paragraph: ({ children }) => (
                    <p>{children}</p>
                  ),
                  hyperlink: blueHyperlinkHtmlSerializer,
                }}
              />
            </div> */}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
