import React, { useState } from 'react';

import MenuItem from '../menu/menuItem';
import Logo from '../logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSocialMediaIconFromType } from '../../utils/getSocialMediaIconFromType';
import { phoneNumberUrlParser } from '../../utils/phoneNumberHtmlSerializer';

const NavbarLarge = ({
  menuItems,
  phoneNumberUrl,
  isCompactLayout,
  logoFluid,
  logoAlt,
  socialMedias,
}) => {

  return (
    <nav
      className={`hidden flex-row items-center xl:flex ${isCompactLayout ? 'justify-between' : 'justify-end'
        } `}
    >
      {isCompactLayout ? (
        <div className='md:w-1/3 lg:w-1/12'>
          <Logo
            logoFluid={logoFluid}
            className={`mx-auto md:mx-0`}
            alt={logoAlt}
          />
        </div>
      ) : null}
      <div className="flex items-end">
        {menuItems
          .filter(
            (mi) =>
              !mi.primary.label.richText[0].text.toLowerCase().includes('home')
          )
          .map((menuItem, index) => {
            return <MenuItem key={index} menuItem={menuItem} />;
          })}
      </div>
      <div className="flex justify-between items-center">

        <div>
          <a
            className='mx-1 rounded bg-white px-3 py-2 font-semibold text-denimblue hover:bg-denimblue hover:text-white'
            href={phoneNumberUrl}
            rel='noopener noreferrer'
          >
            {phoneNumberUrlParser(phoneNumberUrl)}
          </a>
        </div>
        <div className='ml-4 flex items-center'>
          {socialMedias.map((sm, index) => {
            return (
              <div key={index} className='mr-4'>
                <a
                  href={sm.node.data.social_media_account_link.url}
                  target={sm.node.data.social_media_account_link.target || ''}
                >
                  <FontAwesomeIcon
                    icon={getSocialMediaIconFromType(
                      sm.node.data.social_media_type
                    )}
                    inverse
                    size='2x'
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default NavbarLarge;
