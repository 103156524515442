import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import {
  childLinkResolver,
  newLinkResolver,
} from '../../utils/newLinkResolver';
import { RichText } from 'prismic-reactjs';

const MenuItem = ({ menuItem }) => {
  const [isEntered, setIsEntered] = useState(false);

  const buildChildElement = (link, index) => {
    if (link.isExternal) {
      return (
        <a
          key={index}
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className='my-1 text-xs hover:underline'>
            <span>{link.label}</span>
          </div>
        </a>
      );
    } else {
      return (
        <Link
          key={index}
          to={link.url}
          className='my-1 text-xs hover:underline'
        >
          {link.label}
        </Link>
      );
    }
  };

  const resolvedLink = newLinkResolver(menuItem);
  const resolvedChildLinks = menuItem.items
    .filter((f) => f.child_link_url.url)
    .map((field) => {
      return childLinkResolver(field);
    });
  if (resolvedChildLinks.length === 0) {
    return resolvedLink.isExternal ? (
      <a
        href={resolvedLink.url}
        target='_blank'
        rel='noopener noreferrer'
        className='text-primary mx-1 rounded px-1 py-2 text-xs lg:text-xxs font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-white hover:text-darkslategray mega:px-1 mega:text-xs'
      >
        <span>{RichText.asText(menuItem.primary.label.richText)}</span>
      </a>
    ) : (
      <Link
        className='text-primary mx-1 rounded px-1 py-2 text-xs lg:text-xxs font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-white hover:text-darkslategray mega:px-1 mega:text-xs'
        activeClassName='border-b-2'
        to={resolvedLink.url}
      >
        <span>{RichText.asText(menuItem.primary.label.richText)}</span>
      </Link>
    );
  }
  if (resolvedChildLinks.length > 0) {
    return resolvedLink.isExternal && !resolvedLink.url.includes('calendar') ? (
      <a
        href={resolvedLink.url}
        target='_blank'
        rel='noopener noreferrer'
        className='text-primary m-1 rounded px-1 py-3 text-xs lg:text-xxs font-semibold uppercase text-white transition duration-100 ease-in-out hover:bg-white hover:text-darkslategray mega:px-1 mega:text-xs'
      >
        <span>{RichText.asText(menuItem.primary.label.richText)}</span>
      </a>
    ) : (
      <div
        onMouseEnter={() => {
          setIsEntered(true)}
        }
        onMouseLeave={() => setIsEntered(false)}
        className='text-primary mx-1 flex flex-col rounded px-1 py-2 text-xs lg:text-xxs font-semibold uppercase text-white transition duration-100 ease-in-out hover:bg-white hover:text-darkslategray mega:px-1 mega:text-xs'
      >
        <div className='flex items-center'>
          <Link activeClassName='border-b-2' to={resolvedLink.url}>
            <span>{RichText.asText(menuItem.primary.label.richText)}</span>
          </Link>
          <FontAwesomeIcon
            className='ml-2'
            icon={isEntered ? faCaretUp : faCaretDown}
          />
        </div>
        <div
          className={`absolute flex flex-col ${
            isEntered
              ? 'mt-6 block rounded bg-white px-6 py-3 transition duration-100 ease-in-out'
              : 'hidden'
          } h-auto`}
        >
          {resolvedChildLinks
            .filter((l) => l)
            .map((link, index) => {
              return buildChildElement(link, index);
            })}
        </div>
      </div>
    );
  }
};

export default MenuItem;
