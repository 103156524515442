import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const MobileExpandableMenuItem = ({ url, label, isExternal, childLinks }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const buildChildElement = (link, index) => {
    if (link.isExternal) {
      return (
        <a
          key={index}
          href={link.url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className='my-1 ml-3 text-xs'>
            <span>{link.label}</span>
          </div>
        </a>
      );
    } else {
      return (
        <Link key={index} to={link.url} className='my-1 ml-3 text-xs'>
          {link.label}
        </Link>
      );
    }
  };

  return isExternal && !url.includes('calendar') ? (
    <a
      href={url}
      target='_blank'
      rel='noopener noreferrer'
      className='hover:text-primary hover:font-medium hover:underline'
    >
      <div className='px-1' style={{ padding: `0.65rem 0` }}>
        <span>{label}</span>
      </div>
    </a>
  ) : (
    <div className='flex flex-col'>
      <div className='flex items-center' style={{ padding: `0.65rem 0` }}>
        <Link
          className='hover:text-primary hover:font-medium hover:underline'
          to={url}
        >
          <div>
            <span>{label}</span>
          </div>
        </Link>
        <FontAwesomeIcon
          className='ml-2'
          onClick={() => setIsExpanded(!isExpanded)}
          icon={isExpanded ? faCaretUp : faCaretDown}
        />
      </div>
      <div className={`flex flex-col ${isExpanded ? 'block' : 'hidden'}`}>
        {childLinks.map((link, index) => {
          return buildChildElement(link, index);
        })}
      </div>
    </div>
  );
};

export default MobileExpandableMenuItem;
